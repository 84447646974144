/* Base reset for better cross-browser compatibility */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
  padding: 20px;
}

/* Navigation bar base styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0056b3;
  color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  position: relative;
  z-index: 1000;
}

.navbar-brand {
  font-size: 1.1em;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.navbar-brand:hover{
  color: #ffffff !important;
}
.navbar-nav {
  list-style: none;
  display: flex;
}

.nav-item {
  margin-left: 20px;
}

.nav-link {
  color: #dddddd;
  text-decoration: none;
  font-size: 18px;
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #ffffff;
  bottom: -5px;
  left: 0;
  transition: width 0.3s;
}

.nav-link:hover::after {
  width: 100%;
}

.nav-link:hover {
  color: #ffffff;
}

/* Search icon */
.search-icon {
  font-size: 20px;
}

/* Responsive design */
@media (max-width: 992px) {
  .navbar {
    flex-direction: column;
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .nav-item {
    margin: 10px 0;
  }

}

/* Futuristic effects */
.custom-navbar {
    background: linear-gradient(to right, #454984, #0e50b3);
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
}

.custom-navbar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.1), transparent);
  pointer-events: none;
}

/* Hover effects */
.nav-link:hover, .search-icon:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}

/* Animation for links */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-item {
  animation: fadeIn 0.5s ease-in-out forwards;
  opacity: 0;
  animation-delay: calc(0.1s * var(--i));
}

/* Utility classes */
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.container {
  max-width: 1140px;
  margin: auto;
  padding: 0 15px;
}


/* Enhanced hover effects for links */
.nav-link:hover {
    color: #a2d5f2;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
  
  /* Active state for navigation links */
  .nav-item.active .nav-link {
    color: #ffffff;
    border-bottom: 2px solid #a2d5f2;
  }
  
  /* Styling for dropdown menus */
  .dropdown {
    position: relative;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: transparent; /* Updated background color */
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  /* Responsive navbar toggle */
  .navbar-toggler {
    border: none;
    background-color: transparent;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: none;
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    width: 30px;
    height: 30px;
  }
  
  /* Animation for navbar on scroll */
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .scrolled .navbar {
    animation: slideDown 0.3s ease forwards;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 10px 20px;
  }
  
  /* Back to top button */
  .back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0062E6;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    display: none;
  }
  
  .back-to-top.show {
    display: block;
  }
  
  /* Smooth scrolling for anchor links */
  .scroll {
    scroll-behavior: smooth;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .navbar-nav {
      flex-direction: column;
      align-items: center;
    }
  
    .nav-item {
      margin: 5px 0;
    }
  
    .dropdown-content {
      position: static;
    }
  }
  
  /* Additional visual enhancements */
  .navbar-brand {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  
  .navbar-brand:hover {
    transform: scale(1.1);
  }
  
  /* Shadows and depth */
  .custom-navbar, .dropdown-content {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Ensure all animations and transitions are smooth */
  * {
    transition: all 0.3s ease;
  }
/* Add more custom styles as needed */