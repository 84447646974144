.footer {
    background-color: #323A46;
    color: #fff;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .footer-section {
    margin: 10px 10px;
  }
  
  .footer-section h5 {
    margin-bottom: 10px;
  }
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .footer-section {
    flex: 1; /* This ensures that the remaining sections take up the leftover space equally */
  }
  
  .footer-section:first-child {
    flex-basis: 15%; /* Assigns 15% of the footer-content width to the first .footer-section */
  }
  .social-links a {
    display: inline-block;
    margin-right: 10px;
    color: #fff;
    text-decoration: none;
  }
  
  .footer-bottom {
    color: #aaa;
    font-size: 14px;
  }
  
  @media (max-width: 600px) {
    .footer-content {
      flex-direction: column;
    }
  }