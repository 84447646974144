/* General App styling */
html,
body {
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw !important;
  overflow-x: hidden !important;
}

.App {
  font-family: "Arial", sans-serif;
}
/* Hero section styling */
.hero-section {
  background-image: url("");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 70vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  position: relative;
  overflow: hidden;
}
@media (max-width: 768px) {
  .hero-section {
    background-attachment: scroll; /* Fixes background image scaling issue on iOS */
    background-size: auto 100vh; /* Adjusts the size to better fit the screen height */
    background-position: center; /* Adjust as needed to focus on the house */
  }
}
.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: multiply;
  opacity: 0.7;
}


/* Typing animation */
@keyframes typing {
  from {
    max-width: 0;
  }
  to {
    max-width: 600px;
  } /* Adjust this value based on the actual width of your text */
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #55648f;
  }
}
/* Additional styling for responsiveness and aesthetics */
@media (max-width: 768px) {
  h3 {
    font-size: 1.5rem; /* Smaller font size for smaller screens */
  }
}



/* Styling for hover effect */
.hero-section h3:hover {
  color: #8eaad9; /* Change text color on hover */
  transition: color 0.5s ease-in-out; /* Smooth transition for hover effect */
}

/* Additional decorative elements */
.hero-section h3::after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.hero-section h3:hover::after {
  width: 100%;
  background: #8eaad9; /* Matching hover effect color */
}
.hero-section h1 {
  font-size: 4rem;
  margin-bottom: 0.5rem;
  animation: fadeInUp 2s ease-out;
}

.hero-section h3 {
  font-family: "Courier New", Courier, monospace;
  border-right: 0.15em solid #55648f;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0; 
  animation: typing 4s steps(29, end) forwards,
  blink-caret 0.75s step-end infinite;
  margin: 20px auto;
  text-align: center;
  color: purple;
  font-size: 2em !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 550;
}

@media (max-width: 600px) {
  .hero-section h3 {
    color: purple;
    font-size: 1.3em !important;
  }
}

.hero-section p {
  font-size: 1.75rem;
  margin-bottom: 2rem;
  animation: fadeInUp 3s ease-out;
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Button styling */
.btn {
  padding: 1rem 2rem;
  border: none;
  font-weight: bold;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.btn-light {
  background-color: #ffffff;
  color: #007bff;
}

.btn-light:hover {
  background-color: #f8f9fa;
}

.btn-light {
  background-color: #f8f9fa;
  color: #000;
}

.btn-light:hover {
  background-color: #e2e6ea;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Services section styling */
.services-section {
  background-color: #f0f0f0;
}

.services-section .card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.services-section .card:hover {
  transform: translateY(-5px);
}

.services-section img {
  width: 100%;
  height: auto;
}

/* About section styling */
.about-section {
  padding: 4rem 0;
}

/* Contact section styling */
.contact-section {
  background-color: #fff;
  padding: 4rem 0;
}

.contact-section form {
  max-width: 600px;
  margin: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


@media (max-width: 450px) {
  .hero-section{
    height: 50vh !important;
  }
}
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2.5rem;
  }

  .hero-section p {
    font-size: 1.2rem;
  }

  .btn {
    padding: 0.75rem 1.5rem;
  }
}
@keyframes move {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

/* Existing .hero-section styling */
.hero-section {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  position: relative;
  overflow: hidden;
}

/* Adjustments for better responsiveness on small screens */
@media (max-width: 768px) {
  .hero-section {
    background-attachment: scroll; /* Change background-attachment to scroll for better handling on iOS */
  }
}

/* Specific adjustments for iPhone 13 screen size */
@media (max-width: 390px) {
  /* iPhone 13's width in portrait mode */
  .hero-section {
    background-size: auto 100vh; /* Adjust background size to maintain aspect ratio without cropping */
  }
}

.gallery-section .col-md-4:hover img {
  padding: 2px; /* Creates padding within the image */
  background: linear-gradient(
    45deg,
    #7288a9,
    rgb(13, 173, 151),
    rgb(58, 64, 67)
  ); /* Gradient for the "border" */
}
